import { useEffect } from "react";

// images/-/moxie/new/js/components/platform-definition.js
export default function useBodyOSClasses() {
  useEffect(() => {
    const html = document.documentElement;
    const ua = global.navigator.userAgent;

    const iPad = !!ua.match(/(iPad).*OS\s([\d_]+)/);
    const iPhone = !iPad && !!ua.match(/(iPhone\sOS)\s([\d_]+)/);

    if (iPad || iPhone) {
      html.classList.add("ios");
    }

    if (!!ua.match(/(Android)\s+([\d.]+)/) || !!ua.match(/Silk-Accelerated/)) {
      html.classList.add("android");
    }

    if (
      /MSIE 9/i.test(global.navigator.userAgent) ||
      /rv:11.0/i.test(global.navigator.userAgent)
    ) {
      document.body.classList.add("ie11");
    }

    if (/Edge\/\d./i.test(global.navigator.userAgent)) {
      document.body.classList.add("edge");
    }
  }, []);

  return null;
}
