export class AppError extends Error {
  statusCode: number | undefined;

  error: Error | undefined;

  constructor({ statusCode, message, error }: Props) {
    super(message);

    this.statusCode = statusCode;
    this.message = message;
    this.error = error;
  }
}

type Props = {
  statusCode?: number;
  message: string;
  error?: Error;
};
