import GelatoClient from "@bumble/gelato-client-browser";
import { useEffect } from "react";

export const logger = new GelatoClient({
  url: "/jss/js_error.phtml",
  token: "bumble_seasonal_content_hub",
  environment: process.env.NODE_ENV,
});

export const useGelatoLogger = () => {
  useEffect(() => {
    const globalTrack = (event: Event) => {
      // @ts-ignore
      logger.trackError(event.error, {
        origin: "onerror",
      });
    };

    const promiseTrack = (promiseRejectionEvent: PromiseRejectionEvent) => {
      logger.trackError(promiseRejectionEvent.reason, {
        origin: "unhandledrejection",
      });
    };

    global.window.addEventListener("error", globalTrack);
    global.window.addEventListener("unhandledrejection", promiseTrack);

    return () => {
      global.window.removeEventListener("error", globalTrack);
      global.window.removeEventListener("unhandledrejection", promiseTrack);
    };
  }, []);
};
