import { Provider as LocaleProvider } from "lib/locale-context";
import localization from "lib/localization";
import { Localization } from "@bumble/localization/components";
import useBodyOSClasses from "lib/use-body-os-classes";
import type { AppProps } from "next/app";
import React, { useEffect } from "react";
import { Provider as ModalContextProvider } from "components/modal-context";
import supportedLanguageIds, {
  SupportedLanguage,
} from "lib/lexemes/supported-languages";
import { AppError } from "lib/app-error";
// import "../styles/base-bff.scss";
import "../styles/base-bumble-honey-ambassadors.scss";

// @ts-ignore
import { ErrorBoundary } from "@bumble/react-error-boundary";
import { logger, useGelatoLogger } from "lib/use-gelato-logger";
import Head from "next/head";
import Script from "next/script";
import { useAmp } from "next/amp";
import { AmpStyle } from "components/amp-components";

type AppPageProps = {
  lang: SupportedLanguage;
};

function App({ Component, pageProps }: AppProps<AppPageProps>) {
  const lang = getDefaultLanguage(pageProps.lang);
  const lexemes = getLang(lang);
  const isAmp = useAmp();

  useBodyOSClasses();

  useLazyLoadCss();

  useGelatoLogger();

  return (
    <React.StrictMode>
      <ErrorBoundary
        logger={function (error, errorDetails) {
          if (process.env.NODE_ENV === "production") {
            logger.trackError(error, {
              ...errorDetails,
              origin: "ErrorBoundary",
              debugInfo: errorDetails?.debug_info,
            });
          }
        }}
        debug={false}
      >
        <LocaleProvider value={lang}>
          {/* @ts-ignore - @TODO - update in  */}
          <Localization
            instance={localization}
            lang={lang}
            entries={lexemes.lexemes}
            commonWords={lexemes.common}
          >
            <ModalContextProvider>
              <Head>
                {isAmp ? null : (
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, viewport-fit=cover"
                  />
                )}
                {/* {keywords ? <meta name="keywords" content={keywords} /> : null} */}
                {isAmp ? (
                  <script
                    async={true}
                    custom-element="amp-image-lightbox"
                    src="https://cdn.ampproject.org/v0/amp-image-lightbox-0.1.js"
                  />
                ) : (
                  <Script
                    async={true}
                    src="/libs/viewport-units-buggyfill.js"
                  />
                )}
                <link
                  id="deferred-styles"
                  // rel="preload"
                  href="/bff/friendship/butterball-friendsgiving/fonts/Circular20/fonts-circular20.css"
                  as="style"
                />
              </Head>
              {isAmp ? (
                <>
                  <AmpStyle />
                  <amp-image-lightbox
                    id="lightbox1"
                    layout="nodisplay"
                  ></amp-image-lightbox>
                </>
              ) : null}
              <Component {...pageProps} />
            </ModalContextProvider>
          </Localization>
        </LocaleProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default App;

function getDefaultLanguage(lang: string): SupportedLanguage {
  if (supportedLanguageIds.includes(lang as SupportedLanguage)) {
    return lang as SupportedLanguage;
  } else if (typeof lang === "string") {
    // We have a language being passed, but it's not valid, it means user is on an invalid url
    // e.g. /xx/the-buzz or team.bumble.com/xx/open-roles
    throw new AppError({
      statusCode: 404,
      message: `Invalid page language - ${lang}`,
    });
  }

  return "en";
}

function getLang(lang: SupportedLanguage) {
  return require(`lib/lexemes/${lang}`).default;
}

/**
 * Uses "Google approved" way of lazy loading CSS
 * 'deferred-styles' is a noscript tag embedded in the page which the browser doesn't execute for normal environments
 * We just read it and re-add it to the body
 */
function useLazyLoadCss() {
  useEffect(() => {
    const addStylesNode = global.document.getElementById("deferred-styles");
    if (!addStylesNode) {
      return;
    }

    const linkEl = global.document.createElement("link");
    linkEl.rel = "stylesheet";
    linkEl.href = addStylesNode.getAttribute("href") || "";
    global.document.body.appendChild(linkEl);
  }, []);
}
